import * as api from "../Services/api.js";
import Activity from "../../objs/Activity";
import SessionUpdateDetails from "../../objs/SessionUpdateDetails";
import HoldDetails from "../../objs/HoldDetails";

function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export const state = {
  providers: [],
  clients: [],
  activities: [],
};

export const mutations = {
  SET_PROVIDERS(state, payload) {
    state.providers = payload;
  },
  SET_CLIENTS(state, payload) {
    state.clients = payload;
  },
  SET_ACTIVITIES(state, payload) {
    state.activities = payload.map((item) => new Activity(item));
  },
};

export const getters = {
  getUpdateDetails:
    (state, getters, rootState, rootGetters) =>
    ({ sessions, comments, description }) => {
      let updateDetails = new SessionUpdateDetails();
      updateDetails.sessionIds = sessions.map((item) => item.activityId);
      updateDetails.date = new Date().toLocaleDateString("en-US");
      updateDetails.userName = rootGetters["securityModule/fullName"];
      updateDetails.userEmail = rootState.securityModule.dashboardUser.email;
      updateDetails.comments = comments;
      updateDetails.updateDescription = description;
      updateDetails.providerId =
        sessions[0].providerId || sessions[0].providerid;
      return updateDetails;
    },
  getHoldDetails:
    (state, getters, rootState, rootGetters) =>
    ({ sessions, comments }) => {
      let holdDetails = new HoldDetails();
      holdDetails.sessionIds = sessions.map((item) => item.activityId);
      holdDetails.date = new Date().toLocaleDateString("en-US");
      holdDetails.submittedBy = rootGetters["securityModule/fullName"];
      holdDetails.comments = comments;
      return holdDetails;
    },
};

export const actions = {
  clearData({ commit }) {
    commit("SET_ACTIVITIES", []);
  },
  async getProviders({ commit }) {
    return api.get("/officeTask/getActiveProviders").then((response) => {
      commit("SET_PROVIDERS", response.table);
    });
  },
  getClientsForProvider({ commit }, data) {
    return api
      .getQuery("/officeTask/getClientsForProvider", {
        providerId: data.providerId,
      })
      .then((response) => {
        commit("SET_CLIENTS", response.table);
      });
  },
  findByActivityId({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivity", {
        activityId: data.activityId,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByClaimId({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivityByClaim", {
        claimId: data.claimId,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByProviderClientDate({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivities", {
        clientId: data.clientId,
        providerId: data.providerId,
        dateOfService: data.dateOfService,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByProviderClientDateRange({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivitiesDateRange", {
        clientId: data.clientId,
        providerId: data.providerId,
        startDate: data.startDate,
        endDate: data.endDate,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByInvoiceFreeze({ commit }, data) {
    return api
      .get("/officeTask/getActivitiesInvoiceFreeze")
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByWhoInvoiceFreeze({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivitiesWhoInvoiceFreeze", {
        fullName: data.fullName,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByWhoInvoiceFreezeAndDate({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivitiesWhoInvoiceFreezeAndDate", {
        fullName: data.fullName,
        date: data.date,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByWhoInvoiceFreezeAndDateRange({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivitiesWhoInvoiceFreezeAndDateRange", {
        fullName: data.fullName,
        startDate: data.startDate,
        endDate: data.endDate,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByInvoiceFreezeDate({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivitiesInvoiceFreezeDate", {
        date: data.date,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  findByInvoiceFreezeDateRange({ commit }, data) {
    return api
      .getQuery("/officeTask/getActivitiesInvoiceFreezeDateRange", {
        startDate: data.startDate,
        endDate: data.endDate,
      })
      .then((response) => {
        commit("SET_ACTIVITIES", response.table);
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
  uninvoice({ dispatch }, data) {
    // first uninvoice
    return api
      .post("/officeTask/unlockSessions", data.sessions)
      .then((response) => {
        if (Array.isArray(response) && response.length > 0) {
          return dispatch("sendUpdateEmails", {
            sessions: response.filter((item) => item.success),
            comments: data.comments,
            description: "The following sessions have been uninvoiced: ",
          });
        } else {
          return "Error uninvoicing sessions!";
        }
      })
      .catch((error) => {
        return `Error uninvoicing sessions: ${error}`;
      });
  },
  uninvoiceAndFreeze({ getters, dispatch, rootState }, data) {
    // first uninvoice
    return api
      .post("/officeTask/unlockSessions", data.sessions)
      .then((response) => {
        if (Array.isArray(response) && response.length > 0) {
          // then hold
          let holdDetails = getters["getHoldDetails"]({
            sessions: response.filter((item) => item.success),
            comments: data.comments,
          });
          let userEmail = rootState.securityModule.dashboardUser.email;

          return api
            .post("/officeTask/holdSessionInvoicing", holdDetails)
            .then((response) => {
              if (response.success) {
                return dispatch("sendUpdateEmails", {
                  sessions: data.sessions,
                  comments: data.comments,
                  description:
                    "A hold has been placed on the following sessions: <br></br> These sessions have been un-invoiced and are open for adjustments. After adjustments are made, please contact me at " +
                    userEmail +
                    " so that I can unfreeze the sessions.",
                });
              } else {
                return "Error placing hold on the sessions";
              }
            })
            .catch((error) => {
              return `Error placing hold on the sessions: ${error}`;
            });
        } else {
          return "Error uninvoicing sessions!";
        }
      })
      .catch((error) => {
        return `Error uninvoicing sessions: ${error}`;
      });
  },
  unfreeze({ getters, dispatch }, data) {
    let holdDetails = getters["getHoldDetails"]({
      sessions: data.sessions,
      comments: data.comments,
    });

    // first remove hold
    return api
      .post("/officeTask/unholdSessionInvoicing", holdDetails)
      .then((response) => {
        if (response.success) {
          return dispatch("sendUpdateEmails", {
            sessions: data.sessions,
            comments: data.comments,
            description:
              "The hold has been removed from the following sessions: ",
          });
        } else {
          return "Error removing hold from the sessions!";
        }
      })
      .catch((error) => {
        return `Error removing hold from the sessions: ${error}`;
      });
  },
  async sendUpdateEmails({ getters }, { sessions, comments, description }) {
    let failures = [];

    let groupedSessions = groupBy(sessions, "provider");
    for (const provider in groupedSessions) {
      let updateDetails = getters["getUpdateDetails"]({
        sessions: groupedSessions[provider],
        comments,
        description,
      });

      // then send email
      let success = await api
        .post("/officeTask/sendUpdateEmail", updateDetails)
        .then((response) => {
          return response.success;
        })
        .catch((error) => {
          return false;
        });

      if (!success) {
        failures.push(provider);
      }
    }

    if (failures.length > 1) {
      return `Failed to send email to providers: ${failures.join(", ")}`;
    } else {
      return "Provider(s) emailed to notify them of the changes";
    }
  },
};

export default { namespaced: true, state, getters, actions, mutations };
