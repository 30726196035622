<template>
  <div>
    <v-btn
      :disabled="noSelected"
      @click="uninvoice"
      outlined
      small
      class="mx-1"
      >Uninvoice</v-btn
    ><v-btn
      v-if="showHold"
      :disabled="noSelected"
      @click="uninvoiceAndFreeze"
      outlined
      small
      class="mx-1"
      >Uninvoice and Freeze Invoicing</v-btn
    ><v-btn
      v-if="showHold"
      :disabled="noSelected"
      @click="unfreeze"
      outlined
      small
      class="mx-1"
      >Remove Invoicing Freeze</v-btn
    >
    <comments-modal ref="modal"></comments-modal>
  </div>
</template>
<script>
import CommentsModal from "./comments-modal.vue";
export default {
  components: {
    CommentsModal,
  },
  props: ["selected"],
  computed: {
    showHold() {
      return this.$store.getters["securityModule/hasUserRole"](["OfficeAdmin"]);
    },
    noSelected() {
      return !this.selected || this.selected.length < 1;
    },
  },
  methods: {
    async uninvoice() {
      await this.$refs.modal.open("Uninvoice").then(async (comments) => {
        this.$store.commit("uxModule/setShowLoader", true, { root: true });

        let message = await this.$store.dispatch(
          "officeTasksModule/uninvoice",
          {
            sessions: this.selected,
            comments,
          }
        );
        this.$store.commit("uxModule/setShowLoader", false, { root: true });

        this.$store.commit("uxModule/setSnackbarMsg", message, { root: true });
        this.$store.commit("uxModule/setShowSnackbar", true, { root: true });
      });
      this.$emit("onComplete");
    },
    async uninvoiceAndFreeze() {
      await this.$refs.modal
        .open("Uninvoice and Freeze Invoicing")
        .then(async (comments) => {
          this.$store.commit("uxModule/setShowLoader", true, { root: true });

          let message = await this.$store.dispatch(
            "officeTasksModule/uninvoiceAndFreeze",
            {
              sessions: this.selected,
              comments,
            }
          );
          this.$store.commit("uxModule/setShowLoader", false, { root: true });
          this.$store.commit("uxModule/setSnackbarMsg", message, {
            root: true,
          });
          this.$store.commit("uxModule/setShowSnackbar", true, { root: true });
        });
      this.$emit("onComplete");
    },
    async unfreeze() {
      await this.$refs.modal
        .open("Remove Invoicing Freeze")
        .then(async (comments) => {
          this.$store.commit("uxModule/setShowLoader", true, { root: true });

          let message = await this.$store.dispatch(
            "officeTasksModule/unfreeze",
            {
              sessions: this.selected,
              comments,
            }
          );
          this.$store.commit("uxModule/setShowLoader", false, { root: true });

          this.$store.commit("uxModule/setSnackbarMsg", message, {
            root: true,
          });
          this.$store.commit("uxModule/setShowSnackbar", true, { root: true });
        });
      this.$emit("onComplete");
    },
  },
};
</script>
