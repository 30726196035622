<template>
  <v-card class="mx-auto">
    <v-card-title>
      <session-actions :selected="selected" v-on="$listeners"></session-actions>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-title>
      <v-radio-group v-model="groupBy" row label="Group sessions by">
        <v-radio label="Provider" value="provider"></v-radio>
        <v-radio label="Client" value="client"></v-radio>
        <v-radio label="Program" value="programID"></v-radio>
        <v-radio label="Semester" value="semesterID"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="activities"
      :group-by="groupBy"
      sort-by="['dateOfActivity', 'provider', 'client', 'semesterID']"
      sort-desc
      show-select
      item-key="activityId"
      :search="search"
      class="elevation-1 mx-auto"
      disable-pagination
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span
            class="mx-5 font-weight-bold"
            style="font-size: 18px; font-weight: bold"
            >{{ group }}</span
          >
        </td>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <v-tooltip v-if="item.dateInvFreeze" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span>
              Placed on hold by {{ item.whoInvFreeze }} on
              {{ item.formattedDateInvFreeze }}
              <v-icon v-bind="attrs" v-on="on">mdi-note-text-outline</v-icon>
            </span>
          </template>

          <span>"{{ item.invFreezeComments }}"</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import SessionActions from "./session-actions.vue";
export default {
  components: {
    SessionActions,
  },
  data() {
    return {
      selected: [],
      headers: [
        { text: "Provider", value: "provider" },
        { text: "Client", value: "client" },
        { text: "Program", value: "programID" },
        { text: "Semester", value: "semesterID" },
        { text: "Charge Type", value: "description1" },
        { text: "Date of Activity", value: "formattedDateOfActivity" },
        { text: "Start Time", value: "formattedStartTime" },
        { text: "End Time", value: "formattedEndTime" },
        { text: "Duration", value: "duration" },
        { text: "Group Size", value: "groupSize" },
        { text: "Location", value: "description" },
        { text: "Billed On", value: "formattedBilledDate" },
        { text: "Invoiced On", value: "formattedInvoicedDate" },
        { text: "Details", value: "details" },
      ],
      search: "",
      groupBy: "provider",
    };
  },
  mounted() {
    this.selected = [];
  },
  computed: {
    activities() {
      return this.$store.state.officeTasksModule.activities;
    },
  },
};
</script>
