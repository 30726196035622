export const formatDate = (value) => {
  if (value) return new Date(value).toLocaleDateString();
  return "";
};

export const formatTime = (value) => {
  if (value) {
    return new Date(value).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  return "";
};

export const formatDateAndTime = (value) => {
  if (value) {
    return (
      new Date(value).toLocaleDateString() +
      "  " +
      new Date(value).toLocaleTimeString([], { timeStyle: "short" })
    );
  }
  return "";
};