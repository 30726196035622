export default class FeedbackParams {
  fromName?: string | null;
  subject?: string | null;
  message?: string | null;
  fromEmailAddress?: string | null;
  image?: string | null;
  public constructor(params: FeedbackParams = {} as FeedbackParams) {
    let {
      fromName = "",
      subject = "",
      message = "",
      fromEmailAddress = "",
      image = "",
    } = params;
    this.fromName = fromName;
    this.subject = subject;
    this.message = message;
    this.fromEmailAddress = fromEmailAddress;
    this.image = image;
  }
}
