<template>
  <v-dialog v-model="dialog" persistent width="400">
    <v-card class="mx-auto">
      <validation-observer v-slot="{ invalid }">
        <v-card-title>{{ title }} </v-card-title>
        <v-card-text>
          <form-field
            :field="commentsField"
            @fieldChange="onCommentChange"
            :validations="{ required: true }"
          ></form-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark text @click="cancel">Cancel</v-btn>
          <v-btn
            color="primary"
            :dark="!invalid"
            outlined
            :disabled="invalid"
            @click="update"
            >Update</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: "",
      title: "",
      comments: "",
    };
  },
  computed: {
    commentsField() {
      return new FormField({
        label: "Comments/Reason",
        value: this.comments,
        componentName: FieldTypes.TEXT_AREA,
      });
    },
  },
  methods: {
    onCommentChange(value) {
      this.comments = value;
    },
    open(title, message) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    update() {
      this.resolve(this.comments);
      this.dialog = false;
    },
    cancel() {
      this.reject();
      this.dialog = false;
    },
  },
};
</script>
