export default class DashboardUserProfile {
  id?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  legacySystemId?: string | null;
  email?: string | null;
  roles?: string[] | null;
  public constructor(
    params: DashboardUserProfile = {} as DashboardUserProfile
  ) {
    let {
      id = "",
      userName = "",
      firstName = "",
      lastName = "",
      title = "",
      legacySystemId = "",
      email = "",
      roles = new Array<string>()
    } = params;
    this.id = id;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.title = title;
    this.legacySystemId = legacySystemId;
    this.email = email;
    this.roles = roles;
  }
}
