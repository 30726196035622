<template>
  <v-row>
    <v-col cols="2">
      <v-radio-group
        :value="dateQuery"
        @change="$emit('onDateQueryChange', $event)"
      >
        <v-radio label="Specific Date" value="date"></v-radio>
        <v-radio label="Range of dates" value="range"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="3" v-if="dateQuery == 'date'">
      <date-picker
        :value="date"
        :field="{ label: 'Date' }"
        @fieldChange="$emit('onDateChange', $event)"
        :validations="validations.date"
      ></date-picker>
    </v-col>
    <v-col cols="3" v-if="dateQuery == 'range'">
      <date-picker
        :value="startDate"
        :field="{ label: 'Start Date' }"
        @fieldChange="$emit('onStartDateChange', $event)"
        :validations="validations.startDate"
      ></date-picker>
    </v-col>
    <v-col cols="3" v-if="dateQuery == 'range'">
      <date-picker
        :value="endDate"
        :field="{ label: 'End Date' }"
        @fieldChange="$emit('onEndDateChange', $event)"
        :validations="validations.endDate"
      ></date-picker>
    </v-col>
  </v-row>
</template>
<script>
import { required, minDate } from "../../plugins/vee-validate/validation";
export default {
  props: ["dateQuery", "date", "startDate", "endDate"],
  computed: {
    validations() {
      return {
        date: { required: true },
        startDate: { required: true },
        endDate: {
          required: true,
          minDate: this.startDate,
        },
      };
    },
  },
};
</script>
