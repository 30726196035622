<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step :complete="step > 1" step="1" editable>
      Search for sessions
    </v-stepper-step>
    <v-stepper-content step="1">
      <session-lookup @onComplete="step = 2"></session-lookup>
    </v-stepper-content>

    <v-stepper-step :complete="step > 2" step="2">
      Select and Update
    </v-stepper-step>
    <v-stepper-content step="2">
      <sessions-table :key="step" @onComplete="step = 1"></sessions-table>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import SessionLookup from "./session-lookup.vue";
import SessionsTable from "./sessions-table.vue";
export default {
  components: {
    SessionLookup,
    SessionsTable,
  },
  data() {
    return {
      step: 1,
    };
  },
};
</script>
