import Home from "../components/Home/home";
import Callback from "../components/Security/callback";
import SessionUpdates from "../components/SessionUpdates/session-updates";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onNavBar: true,
    onTileGrid: false,
  },
  {
    name: "SessionUpdates",
    path: "/session-updates",
    icon: "unlock-alt",
    title: "Session Updates",
    component: SessionUpdates,
    onNavBar: true,
    onTileGrid: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
  },
];
