export default class SessionUpdateDetails {
  sessionIds?: string[] | null;
  date?: Date | null;
  userName?: string | null;
  userEmail?: string | null;
  comments?: string | null;
  updateDescription?: string | null;
  providerId?: string | null;
  public constructor(params: SessionUpdateDetails = {} as SessionUpdateDetails) {
    let {
      sessionIds = new Array<string>(),
      date = null,
      userName = "",
      userEmail = "",
      comments = "",
      updateDescription = "",
      providerId = ""
    } = params;
    this.sessionIds = sessionIds;
    this.date = date;
    this.userName = userName;
    this.userEmail = userEmail;
    this.comments = comments;
    this.updateDescription = updateDescription;
    this.providerId = providerId;
  }
}
