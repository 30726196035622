import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import store from "../store/index";
Vue.use(VueRouter);

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log("calling shallow authenticate before every route");
  await store.dispatch("securityModule/shallowAuthenticate");
  if (store.state.securityModule.isAuthenticated) {
    next();
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.commit("securityModule/setRequiresAuth", true);
    //authentication is required. Trigger the sign in process, including the return URI
    await store.dispatch("securityModule/authenticate", to.path).then(() => {
      next();
    });
  } else {
    store.commit("securityModule/setRequiresAuth", false);
    next();
  }
});

export default router;
