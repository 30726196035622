<template>
  <v-container>
    <validation-observer v-slot="{ invalid }">
      <v-row>
        <v-radio-group v-model="query" label="Search for sessions by..." row>
          <v-radio
            v-for="query in sessionQueries"
            :key="query"
            :label="query"
            :value="query"
          ></v-radio>
        </v-radio-group>
      </v-row>
      <v-row v-if="showActivityField">
        <v-col cols="3">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              label="Activity ID"
              v-model="activityId"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row v-if="showClaimField">
        <v-col cols="3">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              label="Claim ID"
              v-model="claimId"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <div v-if="showProviderClientFields">
        <v-row>
          <v-col cols="4">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-autocomplete
                label="Provider"
                v-model="providerId"
                :items="providers"
                :item-text="
                  (item) =>
                    `${item.provider} (${item.providerID}), ${item.disciplineID}`
                "
                item-value="providerID"
                clearable
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-autocomplete
                label="Client"
                v-model="clientId"
                :items="clients"
                :item-text="
                  (item) =>
                    `${item.lastName}, ${item.firstName} (DOB: ${formatDate(
                      item.dob
                    )})`
                "
                item-value="clientID"
                clearable
                :disabled="!providerId"
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
        <date-query-fields
          :dateQuery="dateQuery"
          :date="date"
          :startDate="startDate"
          :endDate="endDate"
          @onDateQueryChange="dateQuery = $event"
          @onDateChange="date = $event"
          @onStartDateChange="startDate = $event"
          @onEndDateChange="endDate = $event"
        ></date-query-fields>
      </div>
      <div v-if="showInvoiceStatusField">
        <v-row>
          <v-checkbox
            :value="true"
            disabled
            label="Placed on hold by:"
            hide-details
          ></v-checkbox>
        </v-row>
        <div>
          <v-col cols="4">
            <v-radio-group v-model="freezeByUser">
              <v-radio label="Anyone" :value="false"></v-radio>
              <v-radio :label="fullName" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
        </div>
        <v-row>
          <v-checkbox
            v-model="freezeDate"
            label="Placed on hold on:"
            :hide-details="Boolean(freezeDate)"
          ></v-checkbox>
        </v-row>
        <date-query-fields
          v-if="Boolean(freezeDate)"
          :dateQuery="dateQuery"
          :date="date"
          :startDate="startDate"
          :endDate="endDate"
          @onDateQueryChange="dateQuery = $event"
          @onDateChange="date = $event"
          @onStartDateChange="startDate = $event"
          @onEndDateChange="endDate = $event"
        ></date-query-fields>
      </div>
      <v-row>
        <v-col cols="2">
          <v-btn
            @click="lookupSession"
            :dark="!invalid"
            rounded
            small
            color="primary"
            :disabled="invalid"
          >
            Lookup Session(s)
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import DateQueryFields from "./date-query-fields.vue";
import { SessionQueries } from "../../objs/SessionQueries";
import { formatDate } from "../../utils/format-utils.js";
import { required } from "../../plugins/vee-validate/validation";
export default {
  components: {
    DateQueryFields,
  },
  data: function () {
    return {
      sessionQueries: Object.values(SessionQueries),
      query: SessionQueries.ACTIVITY_ID,
      activityId: "",
      claimId: "",
      providerId: "",
      clientId: "",
      dateQuery: "date",
      date: "",
      startDate: "",
      endDate: "",
      freezeByUser: false,
      freezeDate: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("officeTasksModule/getProviders");
  },
  computed: {
    showActivityField() {
      return this.query == SessionQueries.ACTIVITY_ID;
    },
    showClaimField() {
      return this.query == SessionQueries.CLAIM_ID;
    },
    showInvoiceStatusField() {
      return this.query == SessionQueries.INVOICE_STATUS;
    },
    showProviderClientFields() {
      return this.query == SessionQueries.PROVIDER_AND_CLIENT;
    },
    providers() {
      return this.$store.state.officeTasksModule.providers;
    },
    clients() {
      return this.$store.state.officeTasksModule.clients;
    },
    fullName() {
      return this.$store.getters["securityModule/fullName"];
    },
  },
  watch: {
    async providerId() {
      await this.$store.dispatch("officeTasksModule/getClientsForProvider", {
        providerId: this.providerId,
      });
    },
    query() {
      this.dateQuery = "date";
      this.date = "";
      this.startDate = "";
      this.endDate = "";
      this.freezeByUser = false;
    },
  },
  methods: {
    formatDate,
    async lookupSession() {
      this.$store.dispatch("officeTasksModule/clearData");
      this.$store.commit("uxModule/setShowLoader", true, { root: true });

      let success = false;

      switch (this.query) {
        case SessionQueries.ACTIVITY_ID:
          success = await this.$store.dispatch(
            "officeTasksModule/findByActivityId",
            {
              activityId: this.activityId,
            }
          );
          break;
        case SessionQueries.CLAIM_ID:
          success = await this.$store.dispatch(
            "officeTasksModule/findByClaimId",
            {
              claimId: this.claimId,
            }
          );
          break;
        case SessionQueries.INVOICE_STATUS:
          if (!this.freezeByUser && !this.freezeDate) {
            // all invoice freeze
            success = await this.$store.dispatch(
              "officeTasksModule/findByInvoiceFreeze"
            );
          } else if (this.freezeByUser && !this.freezeDate) {
            // who invoice freeze
            success = await this.$store.dispatch(
              "officeTasksModule/findByWhoInvoiceFreeze",
              {
                fullName: this.fullName,
              }
            );
          } else if (this.freezeByUser && this.freezeDate) {
            if (this.dateQuery == "date") {
              // who and date invoice freeze
              success = await this.$store.dispatch(
                "officeTasksModule/findByWhoInvoiceFreezeAndDate",
                {
                  fullName: this.fullName,
                  date: this.date,
                }
              );
            } else {
              // who and date range invoice freeze
              success = await this.$store.dispatch(
                "officeTasksModule/findByWhoInvoiceFreezeAndDateRange",
                {
                  fullName: this.fullName,
                  startDate: this.startDate,
                  endDate: this.endDate,
                }
              );
            }
          } else if (this.freezeDate) {
            if (this.dateQuery == "date") {
              // date invoice freeze
              success = await this.$store.dispatch(
                "officeTasksModule/findByInvoiceFreezeDate",
                {
                  date: this.date,
                }
              );
            } else {
              // date range invoice freeze
              success = await this.$store.dispatch(
                "officeTasksModule/findByInvoiceFreezeDateRange",
                {
                  startDate: this.startDate,
                  endDate: this.endDate,
                }
              );
            }
          }
          break;
        case SessionQueries.PROVIDER_AND_CLIENT:
          if (this.dateQuery == "date") {
            success = await this.$store.dispatch(
              "officeTasksModule/findByProviderClientDate",
              {
                providerId: this.providerId,
                clientId: this.clientId,
                dateOfService: this.date,
              }
            );
          } else {
            success = await this.$store.dispatch(
              "officeTasksModule/findByProviderClientDateRange",
              {
                providerId: this.providerId,
                clientId: this.clientId,
                startDate: this.startDate,
                endDate: this.endDate,
              }
            );
          }
          break;
      }

      this.$store.commit("uxModule/setShowLoader", false, { root: true });
      if (
        success &&
        this.$store.state.officeTasksModule.activities.length > 0
      ) {
        this.$emit("onComplete");
      } else {
        let message = success
          ? "No sessions found"
          : "An error occurred. Please try again.";
        this.$store.commit("uxModule/setSnackbarMsg", message, {
          root: true,
        });
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
