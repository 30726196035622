import mgr from "../../security/security.js";
import * as api from "../Services/api";
import DashboardUserProfile from "../../objs/DashboardUserProfile.ts";

export const state = {
  isAuthenticated: false,
  user: "",
  requiresAuth: false,
  dashboardUser: new DashboardUserProfile(),
};

export const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setRequiresAuth(state, payload) {
    state.requiresAuth = payload;
  },
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
};

export const getters = {
  hasUserRole: (state) => (roles) => {
    return roles.filter((role) => state.dashboardUser.roles.includes(role)).length > 0;
  },
  fullName: (state) => {
    return `${state.dashboardUser.firstName} ${state.dashboardUser.lastName}`;
  }
};

export const actions = {
  async shallowAuthenticate({ commit, state, dispatch }) {
    console.log("shallow authenticate start");
    await mgr
      .signinSilent()
      .then((user) => {
        console.log("shallow authentcate end");
      })
      .catch((e) => {
        console.log("shallow authenticate failed");
        console.log(e);
      });

    let user = await mgr.getUser();
    if (!!user) {
      commit("setUser", user);
      // load whatever user data is specific to the application here
      if (state.dashboardUser.id == "") {
        await dispatch("getDashboardProfile");
      }
      commit("setIsAuthenticated", true);
    } else {
      commit("setIsAuthenticated", false);
    }
  },
  async authenticate({ commit, state, dispatch }, returnPath) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });
    let user = await mgr.getUser();
    if (!!user) {
      commit("setUser", user);
      // load whatever user data is specific to the application here
      if (state.dashboardUser.id == "") {
        await dispatch("getDashboardProfile");
      }
      commit("setIsAuthenticated", true);
    } else {
      var signedIn = (await returnPath)
        ? mgr.signinRedirect({ state: returnPath })
        : mgr.signinRedirect();
    }

    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
  async logout({ commit, state }) {
    commit("setIsAuthenticated", false);
    await mgr.signoutRedirect();
  },
  async getDashboardProfile({ commit, state }) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });
    var omniId = state.user.profile.sub;
    var sysUserResult = await api.getUser(omniId);
    var sysUser = sysUserResult.data;
    state.dashboardUser.id = omniId;
    state.dashboardUser.userName = sysUser.userName;
    state.dashboardUser.firstName = sysUser.firstName;
    state.dashboardUser.lastName = sysUser.lastName;
    state.dashboardUser.email = sysUser.email;
    state.dashboardUser.title = sysUser.roles[0];
    state.dashboardUser.legacySystemId = sysUser.legacySystemId;
    state.dashboardUser.roles = sysUser.roles;
    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
