export default class HoldDetails {
  sessionIds?: string[] | null;
  date?: Date | null;
  submittedBy?: string | null;
  comments?: string | null;
  public constructor(
    params: HoldDetails = {} as HoldDetails
  ) {
    let {
      sessionIds = new Array<string>(),
      date = null,
      submittedBy = "",
      comments = ""
    } = params;
    this.sessionIds = sessionIds;
    this.date = date;
    this.submittedBy = submittedBy;
    this.comments = comments;
  }
}
