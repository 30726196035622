import { formatDate, formatTime } from "../utils/format-utils";

export default class Activity {
  activityId?: number;
  activitydetailid?: string | null;
  client?: string | null;
  provider?: string | null;
  providerid?: string | null;
  serviceid?: string | null;
  dateOfActivity?: Date | null;
  description?: string | null;
  description1?: string | null;
  duration?: number | null;
  startTime?: Date | null;
  endTime?: Date | null;
  groupSize?: number | null;
  holdPayment?: string | null;
  invFreezeComments?: string | null;
  dateInvFreeze?: Date | null;
  invoiceCafsAmt?: number | null;
  dateInvoicedCafs?: Date | null;
  invoiceaccepted?: string | null;
  invoicedateaccepted?: Date | null;
  whoInvFreeze?: string | null;
  programID?: string | null;
  semesterID?: string | null;
  public constructor(params: Activity = {} as Activity) {
    let {
      activityId = 0,
      activitydetailid = "",
      client = "",
      provider = "",
      providerid = "",
      serviceid = "",
      dateOfActivity = null,
      description = "",
      description1 = "",
      duration = 0,
      startTime = null,
      endTime = null,
      groupSize = 0,
      holdPayment = null,
      invFreezeComments = "",
      dateInvFreeze = null,
      invoiceCafsAmt = 0,
      dateInvoicedCafs = null,
      invoiceaccepted = "",
      invoicedateaccepted = null,
      whoInvFreeze = "",
      programID = "",
      semesterID = "",
    } = params;
    this.activityId = activityId;
    this.activitydetailid = activitydetailid;
    this.client = client;
    this.provider = provider;
    this.providerid = providerid;
    this.serviceid = serviceid;
    this.dateOfActivity = dateOfActivity;
    this.description = description;
    this.description1 = description1;
    this.duration = duration;
    this.startTime = startTime;
    this.endTime = endTime;
    this.groupSize = groupSize;
    this.holdPayment = holdPayment;
    this.invFreezeComments = invFreezeComments;
    this.dateInvFreeze = dateInvFreeze;
    this.invoiceCafsAmt = invoiceCafsAmt;
    this.dateInvoicedCafs = dateInvoicedCafs;
    this.invoiceaccepted = invoiceaccepted;
    this.invoicedateaccepted = invoicedateaccepted;
    this.whoInvFreeze = whoInvFreeze;
    this.programID = programID;
    this.semesterID = semesterID;
  }

  get formattedDateOfActivity() {
    return formatDate(this.dateOfActivity);
  }

  get formattedStartTime() {
    return formatTime(this.startTime);
  }

  get formattedEndTime() {
    return formatTime(this.endTime);
  }

  get formattedBilledDate() {
    return formatDate(this.dateInvoicedCafs);
  }

  get formattedInvoicedDate() {
    return formatDate(this.invoicedateaccepted);
  }

  get formattedDateInvFreeze() {
    return formatDate(this.dateInvFreeze);
  }
}
