import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
Vue.config.devtools = true;

import uxModule from "./Modules/uxModule";
import sharedModule from "./Modules/sharedModule";
import securityModule from "./Modules/securityModule";
import officeTasksModule from "./Modules/officeTasksModule";
const state = {};

const mutations = {};

const actions = {};
const store = new Vuex.Store({
  modules: {
    uxModule: uxModule,
    sharedModule: sharedModule,
    securityModule: securityModule,
    officeTasksModule: officeTasksModule,
  },

  state,
  mutations,
  actions,
});

export default store;
